import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 20 20';
const id = 'help_outline_20';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" id="help_outline_20"><g fill="currentColor"><path d="M10 6.25a1.25 1.25 0 00-1.21.937.75.75 0 01-1.453-.374 2.75 2.75 0 015.413.687c0 1.211-.654 1.84-1.145 2.298l-.008.008c-.478.446-.772.72-.855 1.3a.75.75 0 01-1.484-.212c.158-1.11.803-1.709 1.256-2.13l.068-.062c.447-.417.668-.663.668-1.202 0-.69-.56-1.25-1.25-1.25zM10 15a1 1 0 100-2 1 1 0 000 2z" /><path clip-rule="evenodd" d="M10 1.5a8.5 8.5 0 100 17 8.5 8.5 0 000-17zM3 10a7 7 0 1014 0 7 7 0 00-14 0z" fill-rule="evenodd" /></g></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon20HelpOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon20HelpOutline: FC<Icon20HelpOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 20,
    height: !isNaN(props.height) ? +props.height : 20,
  }));
};

(Icon20HelpOutline as any).mountIcon = mountIcon;

export default Icon20HelpOutline;
